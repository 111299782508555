<template>

  <!-- eslint-disable -->
  <div>
    <div class="flex items-center relative">


      <input                 type="text"
                             class="form-control pl-5"
                v-model="inputText"
                placeholder="Введите текст запроса естественным языком"
                @click="setFocus"
                @keyup.esc="escPressed"
                @keyup.up="increaseIndex(false)"
                @keyup.down="increaseIndex"
                @keyup.enter="suggestionSelected"
                @focus="updateInputFocus"
                @blur="updateInputFocus(false)"
                style="width:100%;"
      />

    </div>
    <!--class="auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-hidden"
    class="auto-suggest__suggestion flex items-center justify-between py-3 cursor-pointer"
    -->


    <ul
        style="position:absolute;display:inline-block;list-style-type: none;background:#202942;"
        :class="{'hidden': !inputFocused}"
        @mouseenter="insideSuggestions = true"
        @mouseleave="insideSuggestions = false"
        @focus="updateInputFocus"
        @blur="updateInputFocus(false)"
        tabindex="-1">
      <li
          class="flex items-center justify-between"
          v-for="(suggestion, index) in filteredData"
          :key="index"
          @mouseenter="currentSelected = index"
          @click="suggestionSelected"
      style="cursor:pointer;">


          <span>{{ suggestion.label }}</span>


      </li>
    </ul>

    <!--
    <div class="pos-rel p-4">
      <div class="table-responsive bg-white shadow rounded" >
    <table class="table mb-0 table-center" :class="{'hidden': !inputFocused}"  >
      <tbody>
      <tr
          v-for="(suggestion, index) in filteredData"
          :key="index"
          @mouseenter="currentSelected = index"
          @click="suggestionSelected">
        <td >{{ suggestion.label }}</td>
      </tr>
      </tbody>
    </table>
      </div>
    </div>
    -->


      </div>

</template>

<script>

  /* eslint-disable */
  export default {
    props: {
      autoselect: {
        type: String,
        default: ''
      },
      autosuggestFlag: {
        type: Number,
        default: 0
      },
      placeholder: {
        type: String,
        default: 'Search..'
      },
      data: {
        type: Object,
        required: true
      },
      showAction: {
        type: Boolean,
        default: false
      },
      inputClassses: {
        type: [String, Object, Array]
      },
      autoFocus: {
        type: Boolean,
        default: false
      },
      showPinned: {
        type: Boolean,
        default: false
      },
      backgroundOverlay: {
        type: Boolean,
        default: false
      },
      searchLimit: {
        type: Number,
        default: 10
      },
      inputText: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        searchQuery: '',
        filteredData: [],
        currentSelected: -1,
        inputFocused: false,
        insideSuggestions: false,
        filteredData_empty: [{ label: '' }],
        element2emit: {
          grade: 1,
          levelOne: 'Бэкенд',
          levelTwo: 'Java',
          label: 'Бэкенд Java Junior'
        },
        filter2sql: {
          pattern: '',
          user_id: 0
        },
        time_last: 0,
        navLight: true,
      }
    },
    watch: {
      // UPDATE SUGGESTIONS LIST
      inputText(val) {
        console.log('inside watch')
				this.$emit('selected', val)

        // console.log(this.data)

        if (this.currentSelected === -2) {
          console.log('after selected')
          console.log(val)
          this.currentSelected = -1
          //this.$emit('selected', this.filteredData_empty[0])
        } else {
          //	if(val === '') {
          if (val.length < 2) {
            console.log('inside search query val == null')
            console.log(val)
            this.inputInit()
            //this.$emit('selected', this.filteredData_empty[0]);

            if (this.bodyOverlay) this.$store.commit('TOGGLE_CONTENT_OVERLAY', false)
          } else {
            var time_current = new Date().getTime()
            var time_diff = time_current - this.time_last

            if (time_diff > 1000) {
              console.log('start axios inside query auto complete')
              console.log(val)
              this.filteredData = []

              this.filter2sql.pattern = val
              //this.filter2sql.user_id = this.$store.state.user_id;
              //console.log('val = ' + val)
              this.axios({
                method: 'post',
                url: '' + this.$hostname + '/query-auto-complete',
                data: this.filter2sql
              }).then(res => {
                this.filteredData = res.data
                this.time_last = new Date().getTime()
                /*
  							if(this.backgroundOverlay && !this.bodyOverlay) this.$store.commit('TOGGLE_CONTENT_OVERLAY', true);

  							let exactEle = this.data.data.filter((item) => {
  								return item.label.toLowerCase().startsWith(this.searchQuery.toLowerCase())
  							});

  							let containEle = this.data.data.filter((item) => {
  								return !item.label.toLowerCase().startsWith(this.searchQuery.toLowerCase()) &&
  								item.label.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
  							});
  							this.filteredData = exactEle.concat(containEle).slice(0,this.searchLimit)
  							*/
                //console.log(this.filteredData)
                //console.log(exactEle)
                //console.log(containEle)
                if (!this.filteredData[0]) {
                  this.currentSelected = -1
                  this.$emit('selected', val)
                }
              })
            }
          }

          /*
  				if(!this.filteredData.length && this.searchQuery) {
  					this.filteredData = [{
  						highlightAction: false,
  						index: -1,
  						label: 'No results found.',
  						labelIcon: 'AlertCircleIcon',
  						url: null,
  					}]
  				}
  				*/
        }
      },
      autoselect(val) {
        //this.searchQuery = val
        /*
  			if (val === 'Бэкенд Java Junior'){
  				this.element2emit.grade = 1
  				this.element2emit.levelOne = 'Бэкенд'
  				this.element2emit.levelTwo = 'Java'
  				this.element2emit.label = 'Бэкенд Java Junior'
  				this.$emit('selected', this.element2emit)
  			}
  			if (val === 'Бэкенд Python Senior'){
  				this.element2emit.grade = 3
  				this.element2emit.levelOne = 'Бэкенд'
  				this.element2emit.levelTwo = 'Python'
  				this.element2emit.label = 'Бэкенд Python Senior'
  				this.$emit('selected', this.element2emit)
  			}
  			*/
      },
      autoFocus(val) {
        if (val) this.focusInput()
        //else this.searchQuery = '';
      },
      filteredData() {
        if (this.currentSelected < 0 && this.filteredData.length) {
          this.currentSelected = 0
        }
        // Prevent selecting if first item in list dont have url e.g. 'No Reult'
        if (this.filteredData[0]) {
          if (!this.filteredData[0].index === -1) {
            this.currentSelected = -1
          }
        }
      }
    },
    computed: {
      bodyOverlay() {
        // console.log('inside overlay')
        // console.log(this.$store.state.bodyOverlay)
        return this.$store.state.bodyOverlay
      },
      actionClasses() {
        return isHighlighted => {
          if (isHighlighted) return `stroke-current text-${this.data.highlightColor}`
        }
      }
    },
    created() {
      this.time_last = new Date().getTime()
      this.searchQuery = this.inputText
    },
    methods: {
      setFocus() {
        if (this.autosuggestFlag === 1) {
          var n = Math.min(25, this.data.data.length)
          this.filteredData = this.data.data.slice(0, n)

          // console.log('this.currentSelected = ' + this.currentSelected)
        }
      },
      escPressed() {
        this.$emit('closeSearchbar')
        this.searchQuery = ''
        this.filteredData = []
      },
      inputInit() {
        // console.log('inside inputInit')
        if (this.showPinned) {
          const starredData = this.data.data.filter(item => item.highlightAction)
          this.filteredData = starredData
        } else {
          // console.log('inputInit -- filter null')
          this.filteredData = []
        }
      },
      updateInputFocus(val = true) {
        // console.log('inside updateInputFocus')
        if (val) {
          //if(this.searchQuery == '') this.inputInit();
          setTimeout(() => {
            this.inputFocused = true
          }, 100)
        } else {
          if (this.insideSuggestions) return
          setTimeout(() => {
            this.inputFocused = false
          }, 100)
          this.escPressed()
        }
      },
      suggestionSelected() {
        // console.log('inside filteredData, index = ' + this.currentSelected)
        if (this.bodyOverlay) {
          // console.log('inside bodyOverlay')
          this.$store.commit('TOGGLE_CONTENT_OVERLAY', false)
        }
        if (this.filteredData.length) {
          //this.searchQuery = '';

          if (this.currentSelected >= 0) {
            //this.$emit('selected', this.filteredData[this.currentSelected])
            console.log('emitting:')
            console.log(this.filteredData[this.currentSelected].label)

            this.$emit('selected', this.filteredData[this.currentSelected].label)
            this.searchQuery = this.filteredData[this.currentSelected].label
            //this.searchQuery = '';
            this.currentSelected = -2
          } else {
            //this.$emit('selected', this.filteredData[0])
            //this.$emit('selected', this.filteredData_empty[0])
            console.log('emitting:')
            console.log(this.searchQuery)
            this.$emit('selected', this.searchQuery)
          }
        }
        this.$emit('closeSearchbar')
        this.filteredData = []
      },

      suggestionSelected_old() {
        /*
  			if(this.bodyOverlay && this.filteredData[0].url) this.$store.commit('TOGGLE_CONTENT_OVERLAY', false);
  			if(this.filteredData.length) {
  				if(this.filteredData[0].url){
  					this.searchQuery = '';
  					*/
        // console.log("inside suggestionSelected")
        // console.log(this.currentSelected)
        // 	console.log(this.filteredData)
        if (this.currentSelected >= 0) {
          //this.$emit('selected', this.filteredData[this.currentSelected])
          this.$emit('selected', this.filteredData[this.currentSelected].label)
          this.searchQuery = this.filteredData[this.currentSelected].label
          this.$emit('closeSearchbar')
        } else {
          this.$emit('selected', this.filteredData[0])
        }
        this.filteredData = []
        //}
        //}
      },
      increaseIndex(val = false) {
        if (!val && this.currentSelected > 0) this.currentSelected--
        else if (
          val &&
          this.currentSelected < this.filteredData.length - 1 &&
          this.filteredData[this.currentSelected + 1].index > -1
        )
          this.currentSelected++
        this.fixScrolling()
      },
      focusInput() {
        this.$refs.input.$el.querySelector('input').focus()
      },
      fixScrolling() {
        if (this.currentSelected > 0) {
          const liH = this.$refs.option[this.currentSelected].clientHeight
          const ulH = this.$refs.scrollContainer.clientHeight
          if (ulH - liH * this.currentSelected < liH) {
            this.$refs.scrollContainer.scrollTop = Math.round((this.currentSelected + 1 - ulH / liH + 1) * liH)
          }
        }
      }
    },
    mounted() {
      if (this.autoFocus) this.focusInput()
    }
  }

</script>

<style lang="css">

  .inputx-auto {
    width: 250px;
  }

  .pos-rel {
    position: relative;
    display: inline-block;
  }

</style>
