<template>

<div>
  <Navbar :nav-light="true" />

  <section class="bg-half bg-light d-table w-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <!--<img src="images/job/Gradle.svg" class="avatar avatar-small" alt="">-->
            <!--<h4 class="title mt-4 mb-3"> {{job.job_title}} </h4>-->
            <h1 class="mdi mdi-briefcase-outline"> </h1>
            <h1 class="title mt-1 mb-1"> Редактирование вакансии </h1>

            <!-- <h1 class="title mt-4 mb-3">   </h1>-->
            <p class="para-desc mx-auto text-muted"></p>





          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->




    <div class="container mt-lg-3">
  <form>

    <div class="row">
      <div class="col-12">
      <div class="form-group">
        <label>Название </label>
        <div class="position-relative">
          <briefcase-icon class="fea icon-sm icons"></briefcase-icon>

          <input
          name="title"
          id="title"
          type="text"
          class="form-control pl-5"
          placeholder="Название вакансии:"
          v-model="job.job_title"
      />
        </div></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Компания </label>
          <div class="position-relative">
            <briefcase-icon class="fea icon-sm icons"></briefcase-icon>

            <input
                name="jobtype"
                v-model="job.job_company"

                type="text"
                class="form-control pl-5"
                placeholder="Название компании:"

            />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6">
        <div class="form-group">
          <label>Город</label>
          <div class="position-relative">
            <home-icon class="fea icon-sm icons"></home-icon>
            <input
                name="name"
                id="first"
                type="text"
                class="form-control pl-5"
                placeholder="Начните писать город для поиска"
                v-model="job.job_city"
            />
          </div>
        </div>
      </div>






     <!-- <div class="col-md-6">
        <div class="form-group">
          <label>Навыки</label>


            <query-auto-complete
                :data="searchAndPinList"
                :inputText="requestParameters.userInputString"
                :autoselect="selectedItem"
                :autosuggest-flag="autosuggestFlag"
                @selected="updateItem"/>


        </div>
      </div> -->

      <div class="col-md-6">
        <div class="form-group">
          <label>Тип устройства :</label>
          <select
              class="form-control custom-select"
              id="Sortbylist-Shop"
              v-model="job.job_type"
          >
            <option>Любой</option>
            <option>Full Time</option>
            <option>Half Time</option>
            <option>Онлайн</option>

          </select>
        </div>
      </div>

      <div class="custom-control custom-switch">
        <div class="form-group mb-0">
          <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
              v-model="job.is_public"
          />
          <label class="custom-control-label" for="customSwitch1"
          >Сделать вакансию публичной</label
          >
        </div>
      </div>

      <!--
      <div class="col-md-6">

        <span v-for="x in vacancy.skill"> {{x}}</span>

      </div>
        -->

      <!--end col-->
      <!--
      <div class="col-md-6">
        <div class="form-group">
          <label>Your Email</label>
          <div class="position-relative">
             <mail-icon class="fea icon-sm icons"></mail-icon>
            <input
                name="email"
                id="email"
                type="email"
                class="form-control pl-5"
                placeholder="Your email :"
            />
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Occupation</label>
          <div class="position-relative">
           <bookmark-icon
                class="fea icon-sm icons"
            ></bookmark-icon>

            <input
                name="name"
                id="occupation"
                type="text"
                class="form-control pl-5"
                placeholder="Occupation :"
            />
          </div>
        </div>
      </div>
      -->
      <!--end col-->
      <!--
      <div class="col-lg-12" style="z-index: -1;">
        <div class="form-group" >
          <label>Требования, обязанности, условия</label>


            <textarea
                name="comments"
                id="comments"
                rows="4"
                class="form-control pl-5"
                placeholder="Детальное описание вакансии :"
            ></textarea>

        </div>
      </div>
      -->

    </div>



    <div class="row mt-4">
      <div class="col-12">
      <h5>Описание вакансии</h5>
      <quill-editor
          ref="editor"
          v-model="job.description"
          :options="editorOption"
      />
      </div>
    </div>

    <br><br>
    <br>
     <!-- <div class="row"><h5>Воронка вакансии</h5></div>


    <div class="row table-responsive bg-white shadow rounded">
      <table class="table mb-0 table-center table-nowrap">
        <thead>
        <tr>
          <th scope="col">Порядок</th>
          <th scope="col">Этап</th>
          <th scope="col">Резюме</th>
          <th scope="col"><a @click="newFunnelStage" class="btn btn-outline-primary btn-sm"> Добавить этап </a></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(funnel_stage,ix) in funnel_stages">
          <th scope="row">
            <span v-if="funnel_stage.editMode">{{ funnel_stage.funnel_stage_order_id }}</span>
            <div v-if="!funnel_stage.editMode" class="form-group mt-2">
              <div class="position-relative">
                <input class="form-control" style="width:25%;" type="text"
                       v-model="funnel_stages[ix].funnel_stage_order_id" />
              </div>
            </div>
          </th>
          <td><span v-if="funnel_stage.editMode">{{ funnel_stage.funnel_stage_name }}</span>
            <div v-if="!funnel_stage.editMode" class="form-group mt-2">
              <div class="position-relative">
                <input class="form-control" style="width:75%;" type="text"
                       v-model="funnel_stages[ix].funnel_stage_name" />
              </div>
            </div>
          </td>
          <td> {{ funnel_stage.resumes }} </td>
          <td>
            <a class="btn btn-icon btn-outline-light" v-if="funnel_stage.editMode" @click="funnel_stage.editMode=!funnel_stage.editMode">
              <edit-icon class="fea icon-sm"></edit-icon></a>
            <a class="btn btn-icon btn-outline-success" v-if="!funnel_stage.editMode" @click="saveFunnelStage(ix)">
              <check-icon class="fea icon-sm"></check-icon></a>
            <a class="btn btn-icon btn-outline-danger ml-2" v-if="!funnel_stage.editMode" @click="deleteFunnelStage(ix)">
              <trash-icon class="fea icon-sm"></trash-icon></a>
          </td>
        </tr>

        </tbody>
      </table>
    </div> -->



    <div class="row">
      <div class="col-sm-12">

        <a  @click="createNew" class="btn btn-primary"> Сохранить </a>
      </div>

    </div>

  </form>
    </div>
  </section>


  <Footer />

</div>
</template>

<script>


import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";

import QueryAutoComplete from './QueryAutoComplete';

import store from "@/store/store";

import {HomeIcon, BriefcaseIcon, EditIcon, TrashIcon, CheckIcon} from "vue-feather-icons";


/*
import { Editor, EditorContent } from '@tiptap/vue-2'
import MenuBar from './util/MenuBar';

import StarterKit from '@tiptap/starter-kit'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Highlight from '@tiptap/extension-highlight'
import CharacterCount from '@tiptap/extension-character-count'

import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text';

import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
*/


export default {
  name: "NewVacancy",
  components: {
        Navbar,
        Footer,
        QueryAutoComplete,
        HomeIcon,BriefcaseIcon,EditIcon,TrashIcon, CheckIcon
        //MenuBar,EditorContent,TaskList,TaskItem,Highlight,CharacterCount,
      },

  mounted() {
      this.getJobDetails();
  //  this.getNewId();

    /*this.editor = new Editor({
      content: '<p>I’m running tiptap with Vue.js. 🎉</p>',
      extensions: [
        StarterKit,
      ],
    })*/

    /*
    this.editor = new Editor({
      extensions: [
        //StarterKit,
        Document,
        Paragraph,
        Text,
        //Highlight,
        //TaskList,
        //TaskItem,
          Bold,Italic,
      ],
      content: `<p> hello </p>`
    });

    this.editor_obj = this.editor;

    console.log(this.editor);
    */
  },

  beforeDestroy() {
    //this.editor.destroy()
  },

  data() {
    return {

      input2sql: {
        vacancy_id: 1,
        user_id:1,
      },
      job: {job_id: '1',},
      job_createdDate: '',


      new_vacancy_id:0,

      jobDescription : {
        user_id: 0 ,
        job_id: 0 ,
        job_title: '',
        job_city: '',
        job_type: '',
        job_company: '',
        job_salary_from: 0,
        job_salary_to: 0,
        job_salary_currency: '',
        job_description: '',
        job_status: 'Активная',
        createFlag: 0,
        is_public: false,
      },

      in_funnel_sql : {
        id: 0 ,
        user_id: 1,

        vacancy_id: 1,
        vacancy_name: '',
        funnel_stage_order_id: 1,
        funnel_stage_name: '',

        editFlag: false,
      },
      funnel_stages: [],


      content: '<p></p>',
      editorOption: {

        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        }
      },

      editor: null,
      editor_obj: {},
      vacancy: {
        user_id:1,
        name: '',
        city: '',
        skill: [],
        description: '',
        funnel_stages: [],
        vacancy_id: 0,
      },

      selectedItem: '',
      autosuggestFlag: 0,
      searchAndPinList: {},

      feedbackParameters: {
        isRight: true,
        comment: null
      },
      requestParameters: {
        addedModelIsClassifier: false,
        queryID: null,
        userInputString: null,
        selectedProject: "SberPrime",
        selectedSource: "https://ai4banki.ru/api/chatbot_prime_katya_clf",
        needToClassify: true,
        addedSourceName: null,
        addedSourceURL: null,
        addedSourceProject: null
      },
      currentPlotParameters: {
        intentProbText: "",
        intentText: "",
        probText: "",
        title: null,
        queryID: null,
        queryText: null,
        rawText: null,
        type: 'lineplot',
        prob: null,
        status: true,
        somethingBad: false,
        allGood: false
      },
    }
  },

  methods: {


    getJobDetails() {

      this.input2sql.user_id = store.state.user_id;
      this.input2sql.vacancy_id = this.$route.params.vacancy_id;


      console.log('inside getJobDetails ')
      console.log(this.input2sql)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/job-description/get?access_token=' + store.state.token,
        data: this.input2sql,
      }).then((res) => {
        this.job = res.data.jobDescription;
        this.job_createdDate = res.data.date_from;
        console.log('resume')
        console.log(res.data);
      });


    },

    updateItem(e) {
      console.log('update item');
      console.log(e);
      this.selectedItem = '';
      /*if (e.type === 'query'){
        this.requestParameters.userInputString = e.label;
      }*/
      this.requestParameters.userInputString = e;

    },

    getNewId() {
      this.in_funnel_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/new-vacancy-id?access_token=' + store.state.token,
        data: this.in_funnel_sql,
      }).then((res) => {
        if (res.data.status==='ok') {
          this.new_vacancy_id = res.data.new_vacancy_id;
          console.log(' new vacancy id ' + this.new_vacancy_id)
        }
      });
    },

    createNew() {

      console.log('inside create new job')
      this.jobDescription.user_id = store.state.user_id;
      this.jobDescription.job_id = this.$route.params.vacancy_id;
          this.jobDescription.job_title= this.job.job_title;
          this.jobDescription.job_city= this.job.job_city;
          this.jobDescription.job_type= this.job.job_type;
          this.jobDescription.job_company= this.job.job_company;
          this.jobDescription.job_description = this.job.job_description;
      this.jobDescription.createFlag=1

      this.jobDescription.funnel_stages = this.funnel_stages;
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/job-description/create-update?access_token=' + store.state.token,
        data: this.jobDescription,
      }).then((res) => {
        if (res.data.status==='ok') {

         // this.$vs.notify({
         //   time: 8000,
         //   title: 'Успех',
         //   text: 'Данные сохранены',
         //   color: 'success'
         // });

          let route = this.$router.resolve({path: '/job-list'});
          window.open(route.href, '_self');

        }
      });
    },


    newFunnelStage() {

        this.in_funnel_sql.user_id = store.state.user_id;

        this.in_funnel_sql.vacancy_id = this.new_vacancy_id;
        this.in_funnel_sql.vacancy_name = this.vacancy.name;

        this.in_funnel_sql.funnel_stage_order_id = 1;
        this.in_funnel_sql.funnel_stage_name = 'Новый этап';
        this.in_funnel_sql.id=0;
        this.in_funnel_sql.editFlag = false;

        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/prod/vacancy-funnel/save-stage?access_token=' + store.state.token,
          data: this.in_funnel_sql,
        }).then((res) => {
          if (res.data.status === 'ok') {
            this.getAllFunnelStages();
          }
        });

    },

    getAllFunnelStages(){

      this.in_funnel_sql.user_id = store.state.user_id;
      this.in_funnel_sql.vacancy_id = this.new_vacancy_id;

      var url = '' + this.$hostname + '/prod/vacancy-funnel/get-stage?access_token=' + store.state.token;
      console.log('inside getAllFunnelStages '+ url);

      this.axios({
        method: 'post',
        url: url,
        data: this.in_funnel_sql,
      }).then((res) => {
        if (res.data.status === 'ok') {
          this.funnel_stages = res.data.funnel_stages;
        }
      });

    },



  }
}
</script>

